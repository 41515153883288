import { buildGlobe } from "./globe.js";
import { buildElephant, animateElephant } from "./elephant.js";
import { buildCube } from "./cube.js";
import { buildText2, buildText } from "./text.js";
import { buildScroll, registerScrollHandler } from "./scroll.js";

buildGlobe();
buildElephant();

const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
if (!isMobile) {
    buildScroll();
    function handleScrollChange(index) {
        if (index == 0) {
            animateElephant(true);
        } else {
            animateElephant(false);
        }
    }

    registerScrollHandler(handleScrollChange);

}

buildCube();
//buildText2();