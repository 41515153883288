import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/Addons.js'


export function buildCube() {
    /**
     * Base
     */


    // Canvas
    const canvas = document.querySelector('canvas.cube')

    // Scene
    const scene = new THREE.Scene()

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
        pixelRatio: Math.min(window.devicePixelRatio, 2)
    }

    window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
        sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(sizes.pixelRatio)
    })

    /**
     * Camera
     */

    // Base camera
    const cameraGroup = new THREE.Group()
    scene.add(cameraGroup)

    const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)

    camera.position.x = 7.5
    camera.position.y = 5.6
    camera.position.z = 6.6

    cameraGroup.add(camera)

    // Controls
    const controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true
    controls.maxPolarAngle = Math.PI / 2;
    controls.enableZoom = false;
    controls.target.y = 3.5

    /**
     * Cursor
     */
    const cursor = {}
    cursor.x = 0
    cursor.y = 0

    window.addEventListener('mousemove', (mouseEvent) => {
        cursor.x = mouseEvent.clientX / sizes.width - 0.5
        cursor.y = mouseEvent.clientY / sizes.height - 0.5
    })

    /**
     * Lights
     */

    // Ambiant light
    const ambientLight = new THREE.AmbientLight(0x002E94, 3)
    scene.add(ambientLight)

    // Directional light
    const directionalLight = new THREE.DirectionalLight(0x002E94, 2)
    directionalLight.position.set(-2, 10, -3)

    scene.add(directionalLight)

    // //Point Lights

    // //Top point light
    // const plTop = new THREE.PointLight(0xffffff, 0.5, 2, 1)
    // plTop.position.set(0, 4.1, 0)
    // scene.add(plTop)

    // // const plhTop = new THREE.PointLightHelper(plTop, 0.2)
    // // scene.add(plhTop)

    // //Front point light
    // const plFront = new THREE.PointLight(0xffffff, 0.5, 2, 1)
    // plFront.position.set(0, 3.25, 1)
    // scene.add(plFront)

    // // const plhFront = new THREE.PointLightHelper(plFront, 0.2)
    // // scene.add(plhFront)

    // //Left point light
    // const plLeft = new THREE.PointLight(0xffffff, 0.5, 2, 1)
    // plLeft.position.set(-1, 3.25, 0)
    // scene.add(plLeft)

    // // const plhLeft = new THREE.PointLightHelper(plLeft, 0.2)
    // // scene.add(plhLeft)

    // //Right point light
    // const plRight = new THREE.PointLight(0xffffff,0.5, 2, 1)
    // plRight.position.set(1, 3.25, 0)
    // scene.add(plRight)

    // // const plhRight = new THREE.PointLightHelper(plRight, 0.2)
    // // scene.add(plhRight)

    // //Back point light
    // const plBack = new THREE.PointLight(0xffffff, 0.5, 2, 1)
    // plBack.position.set(0, 3.25, -1)
    // scene.add(plBack)

    // // const plhBack = new THREE.PointLightHelper(plBack, 0.2)
    // // scene.add(plhBack)

    /**
     * Models
     */

    const gltfLoader = new GLTFLoader()
   
    const normals = [
        new THREE.Vector3(1, 0, 0),  // Right face
        new THREE.Vector3(-1, 0, 0), // Left face
        new THREE.Vector3(0, 1, 0),  // Top face
        new THREE.Vector3(0, -1, 0), // Bottom face
        new THREE.Vector3(0, 0, 1),  // Front face
        new THREE.Vector3(0, 0, -1)  // Back face
    ];
    const elements = [  // IDs of HTML elements corresponding to each face
        "rightFaceElement", "leftFaceElement", "topFaceElement",
        "bottomFaceElement", "frontFaceElement", "backFaceElement"
    ];

    let cube
    let mixer, previousVisibilities = new Map();
    const animationsMap = new Map();
    const actionsMap = new Map();
  
    gltfLoader.load(
        'https://d2t5w20sxjmcrj.cloudfront.net/assets/models/Cube/glTF/ge-groupCube.gltf',
        (gltf) => {
            gltf.scene.traverse(child => {
                if (child.isMesh && child.material) {

                    const physicalMaterial = new THREE.MeshPhysicalMaterial({
                    color: child.material.color,
                    map: child.material.map,
                    emissive: 0x002E94,
                    emissiveIntensity:0, 
                    roughness: 1,
                    metalness: 0,
                    ior:1.1,
                    transmission: 0
                    });
                child.material = physicalMaterial;
                }
            })

            cube = gltf.scene;
            scene.add(cube)

            mixer = new THREE.AnimationMixer(cube);

            gltf.animations.forEach((clip, index) => {
                animationsMap.set(index, clip);
                const action = mixer.clipAction(clip);
                actionsMap.set(index, action);
                previousVisibilities.set(index, false); // Initialize visibility states
            });

            const innerCube = cube.getObjectByName('Cube-innerCube') // this works!? 
                if(innerCube) {
                const physicalMaterial = new THREE.MeshPhysicalMaterial({
                emissive: 0xfffff
                })
            innerCube.material = physicalMaterial
            }
        }
    )

    function updateFaceVisibility(obj, camera) {
        var visibleElement;
        var visibleIndex = 0;

        for (let index = 0; index < normals.length; index++) {
            let normal = normals[index];
            let worldNormal = new THREE.Vector3().copy(normal).applyQuaternion(obj.quaternion);
            let cameraDirection = new THREE.Vector3().subVectors(camera.position, obj.position).normalize();

            let isVisible = worldNormal.dot(cameraDirection) > 0.60;
            let bottomVisible = worldNormal.dot(cameraDirection) > -0.33 && elements[index] == "bottomFaceElement";

            if (isVisible || bottomVisible) {
                visibleElement = elements[index];
                visibleIndex = index;
            }

            if (bottomVisible) {
                break;
            }
        }

        normals.forEach((normal, index) => {

            const wasVisible = previousVisibilities.get(index)

            if (visibleIndex != index && wasVisible) {
                handleAnimation(index, false);
                previousVisibilities.set(index, false);
                document.getElementById(elements[index]).style.display = 'none';
            }
        });

        if (visibleElement) {

            const wasVisible = previousVisibilities.get(visibleIndex)

            if (!wasVisible) {
                handleAnimation(visibleIndex, true);
                previousVisibilities.set(visibleIndex, true);
                document.getElementById(visibleElement).style.display = 'block';
            }

            
        }
    }

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        //alpha: true
    })
    //renderer.shadowMap.enabled = true
    //renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(sizes.pixelRatio)

    var animationMapping = {
        4: 2,
        1: 4,
        2: 5,
        3: 1,
        0: 3,
        5: 0
    }

    var materialMapping = {
        5: 1,//good
        0: 5,//good
        1: 6,//good
        2: 7, //good
        3: 2,
        4: 3//good
    }

    /**
     * Animate
     */

    function handleAnimation(oldIndex, isVisible) {

        var index = animationMapping[oldIndex];
        var materialIndex = materialMapping[oldIndex];
        const action = actionsMap.get(index);
        if (!action) return;

        action.clampWhenFinished = true;

        const isCurrentlyPlaying = action.isRunning();
        const needsToPlayForward = isVisible;
        const needsToPlayBackward = !isVisible;

        //console.log('handleAnimation ' + index)

        if (needsToPlayForward) {
           // console.log('forward ' + index);

            if (isCurrentlyPlaying && action.timeScale == 1) {
                console.log('isCurrentlyPlaying' + action)
                return;
            }
            cube.children[materialIndex].material.transmission = 1;
            cube.children[materialIndex].material.emissiveIntensity = 1;
            action.reset();
            action.timeScale = 1.5;
            action.setLoop(THREE.LoopOnce);
            console.log('play ' + index);
            action.play();
        } else if (needsToPlayBackward) {
            //console.log('needsToPlayBackward ' + index);
            if (isCurrentlyPlaying && action.timeScale == -1) {
                return;
            }

            cube.children[materialIndex].material.transmission = 0;
            cube.children[materialIndex].material.emissiveIntensity = 0;
            action.reset();
            action.timeScale = -1.5;
            action.setLoop(THREE.LoopOnce);
            action.time = action.getClip().duration
            console.log('hide ' + index);
            action.play();
        }

        cube.children[materialIndex].material.needsUpdate = true;
    }  

    const clock = new THREE.Clock()

    const tick = () => {
        const delta = clock.getDelta();
        if (mixer) mixer.update(delta); // Update the mixer which will handle playing actions
        if (cube) updateFaceVisibility(cube, camera);

        // Update controls
        controls.update()

        //console.log("X" + camera.position.x);
        //console.log("Y" + camera.position.y);
        //console.log("Z" + camera.position.z);

        // Render
        renderer.render(scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()

}