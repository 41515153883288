import * as THREE from 'three'
import {

    Vector3,
    LineBasicMaterial, Geometry, Line, BufferGeometry

} from "three";
import { GLTFLoader } from 'three/examples/jsm/Addons.js'
import GUI from 'lil-gui'
import gsap from 'gsap'

var elephantShouldAnimate = true;
export function buildElephant() {

    /**
     * Base
     */

    // Debug
    const gui = new GUI({ closeFolders: false, })
    gui.close()
    gui.hide()

    // Canvas
    const canvas = document.querySelector('canvas.elephantWebgl')

    // Scene
    const scene = new THREE.Scene()

    // const fog = new THREE.Fog('#A8B9D5', 1, 13)
    // scene.fog = fog

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
        pixelRatio: Math.min(window.devicePixelRatio, 2)
    }

    /**
     * Events
     */
    const cursor = {}
    cursor.x = 0
    cursor.y = 0

    window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
        sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(sizes.pixelRatio)
    })

    let startTime = Date.now();
    //show hide the debug ui after all the complaining 
    window.addEventListener('keydown', (event) => {
        if (event.key == 'h') {
            lightsAnimationClip.restart();
        }
    })

    window.addEventListener('mousemove', (event) => {
        cursor.x = (event.clientX / window.innerWidth) * 2 - 1;
        cursor.y = -(event.clientY / window.innerHeight) * 2 + 1;
    })

    /**
     * Camera
     */

    // Base camera
    const cameraGroup = new THREE.Group()
    scene.add(cameraGroup)

    const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)

    camera.position.x = 7.5
    camera.position.y = 0.5
    camera.position.z = 6.5

    camera.rotation.x = 0.1

    //gui to help place the camera in scene
    gui.add(camera.position, 'x', -10, 10, 0.001,).name('camera x')
    gui.add(camera.position, 'y', 0, 15, 0.001,).name('camera y')
    gui.add(camera.position, 'z', 2, 15, 0.001,).name('camera z')
    gui.add(camera.rotation, 'x', -1, 1, 0.001,).name('cameraRotation x')
    gui.add(camera.rotation, 'y', -1, 1, 0.001,).name('cameraRotation y')

    cameraGroup.add(camera)

    /**
     * Lights
     */

    // Ambiant light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.15)
    scene.add(ambientLight)

    // Directional light
    const directionalLight = new THREE.DirectionalLight(0x002E94, 0)
    directionalLight.position.set(0, 10, 2)

    gui.add(directionalLight.position, 'x', -10, 10, 0.001,).name('dLight x')
    gui.add(directionalLight.position, 'y', -10, 10, 0.001,).name('dLight y')
    gui.add(directionalLight.position, 'z', -10, 10, 0.001,).name('dLight z')

    scene.add(directionalLight)

    directionalLight.castShadow = false
    directionalLight.shadow.mapSize.set(2048, 2048)
    directionalLight.shadow.camera.near = 0.5
    directionalLight.shadow.camera.far = 20
    directionalLight.shadow.camera.left = - 20
    directionalLight.shadow.camera.top = 20
    directionalLight.shadow.camera.right = 20
    directionalLight.shadow.camera.bottom = -20

    //Spot light
    const bgSpotLight = new THREE.SpotLight(0x003EC8, 0, 20, Math.PI * 0.1, 1, 0.5) // color, intensity, distance, angle, penumbra, decay

    gui.add(bgSpotLight.position, 'x', -10, 10, 0.001,).name('spotLight x')
    gui.add(bgSpotLight.position, 'y', -10, 10, 0.001,).name('spotLight y')
    gui.add(bgSpotLight.position, 'z', -10, 10, 0.001,).name('spotLight z')
    gui.add(bgSpotLight.rotation, 'x', -10, 10, 0.001,).name('spotLight rot x')
    gui.add(bgSpotLight.rotation, 'y', -10, 10, 0.001,).name('spotLight rot y')
    gui.add(bgSpotLight.target.position, 'z', -10, 0, 0.001,).name('spotLight target')

    scene.add(bgSpotLight)
    scene.add(bgSpotLight.target)

    bgSpotLight.castShadow = false
    bgSpotLight.shadow.mapSize.set(1024, 1024)
    bgSpotLight.position.set(10, 10, -10)
    bgSpotLight.target.position.z = -5

    //Elephant strobe light
    const spotLightHeroLight = new THREE.SpotLight(0xffffff, 0, 8.5, Math.PI * 0.5, 0.9, 0.005) // color, intensity, distance, angle, penumbra, decay
    scene.add(spotLightHeroLight)
    scene.add(spotLightHeroLight.target)

    spotLightHeroLight.castShadow = false
    spotLightHeroLight.shadow.mapSize.set(1024, 1024)
    spotLightHeroLight.position.set(3.1, 4.5, 0)
    spotLightHeroLight.target.position.set(3.1, 0, 0)
          
    //Helpers

    // const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
    // scene.add(directionalLightCameraHelper)
    // const bgSpotLightCameraHelper = new THREE.CameraHelper(bgSpotLight.shadow.camera)
    // scene.add(bgSpotLightCameraHelper)
    //const spotLightHeroLightCameraHelper = new THREE.CameraHelper(spotLightHeroLight.shadow.camera)
    //scene.add(spotLightHeroLightCameraHelper)

    /**
     * Animate lights for intro 
     */
    const lightsAnimationClip = gsap.timeline({paused: true})

    //Spot light
    lightsAnimationClip.to(bgSpotLight, {intensity:40, angle:Math.PI*0.8, duration:5})
    lightsAnimationClip.to(bgSpotLight.position, {x:0}, "<")

    //Directional light 
    lightsAnimationClip.to(directionalLight, { intensity: 4, duration: 5}, "<")

    //Ambiant light
    lightsAnimationClip.to(ambientLight, { intensity: 1, duration: 5}, "<")  

    //Hero spotlight
    lightsAnimationClip.to(spotLightHeroLight, { intensity:40, distance: 3.5, angle:Math.PI*0.3, duration:6.8, ease:"power1.inOut"},"<")
    lightsAnimationClip.to(spotLightHeroLight.position, { y:1.5, duration:6.8, ease:"power1.inOut" },"<")
    lightsAnimationClip.to(spotLightHeroLight.position, { y:4.5, duration:0.5, ease:"expo.inOut" }, ">")
    lightsAnimationClip.to(spotLightHeroLight, { intensity:4, distance: 8.5, angle:Math.PI*0.6, duration:0.5, ease:"expo.inOut"}, "<")
    
    /**
     * Textures
     */
    const textureLoader = new THREE.TextureLoader()
    const matcapTexture = textureLoader.load('https://d2t5w20sxjmcrj.cloudfront.net/assets/img/matCap1.png')
    matcapTexture.colorSpace = THREE.SRGBColorSpace
    
    /**
     * Models
     */
    const gltfLoader = new GLTFLoader()

    const gltfModel = new THREE.Group() //adding the elephant to a group and animating that
    const EBNGroup = new THREE.Group() //adding the elephant business nodes to this group
    const particleGroup = new THREE.Group() //adding the particle elephant to this group
    scene.add(gltfModel)

    //load the plane
    gltfLoader.load(
        'https://d2t5w20sxjmcrj.cloudfront.net/assets/models/Plane/glTF/plane.gltf',
        (gltf) => {
            gltf.scene.traverse(child => {
                if (child.isMesh) {
                    child.receiveShadow = true
                }
            })

            gltf.scene.translateY(-1.8)
            scene.add(gltf.scene)
        }
    )

    //Load the environment
    gltfLoader.load(
        'https://d2t5w20sxjmcrj.cloudfront.net/assets/models/Plane/glTF/environment.gltf',
    (gltf) => {
        gltf.scene.traverse (child =>
             {
                 if (child.isMesh)
                 {
                    child.material.side = THREE.DoubleSide
                     child.castShadow = false
                 }
        })

            gltf.scene.translateY(-1.8)
            scene.add(gltf.scene)
        }
    )   

    let EBNMesh = []

    var logos = [
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201f755a938ee7929a570b_Brand%3DAdvance%20Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201f85254282987ea3953e_Brand%3DAiR%20Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201f9e115b0b3bdf0b222d_Brand%3DCamarade_Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201fb082a395070f70a9ea_Brand%3DClearsky%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/6620f69f00a791d009f80142_Brand%3DCognetics%20logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/6620f68a08d0d180b37e5d6b_Brand%3DDiceros%20Final%20Logo_Colour_2%20Feb%202021%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/6620f6653919f9f47c103823_Brand%3DEMS%20Logo-Full%20colour%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201f0a89119ce945a90ce9_Brand%3DEPI-USE%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201ccfd69be0d5fc6598d8_Brand%3DEPI-USE%20Global%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201ed9ce49dd0d8e369ea6_Brand%3DEPI-USE%20Labs%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201ebedcd450d56a9848f0_Brand%3DEvolutio%20Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201e72254282987ea2a03f_Brand%3DG3G-Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201e273760b867fde04464_Brand%3DGlyde%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201e0d052ca2835cdd1463_Brand%3DHyperboliq%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201db1fa21c146f21eed9d_Brand%3DK5%20Business%20Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/6620f60c2b1e767b7b149bed_Brand%3DKreon%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/661ce40f88dd92f7fc55ab75_Liminal%20Logo_RGB_Logo-no-shadow.png',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/6620f5e6fe2e54612a52406a_Brand%3DLogBox%20Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201d199b813c1fc7999a12_Brand%3DMetagrated_Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201cfc4be26e97e3838a5f_Brand%3DRivor%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201ce849aecd87d790d702_Brand%3DSapconet_Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201cabae7a97e2ee80a356_Brand%3DStratview-Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201c9981ccdfa90fb5f260_Brand%3DTusk%20Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201c6c83f2dae447aa06df_Brand%3DValcann_-_Logo_Nova_Assinatura%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201c7f9b813c1fc799079f_Brand%3DVantage%20Point%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201c53a0353e93cb1e9aa1_Brand%3DZimele_Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201dfb2b0ffe65be9c9adb_Brand%3DiD2-metering-logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201de331fc0943eeadf10a_Brand%3DiLAB-Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/6620f62ee5ce17ec77fd026d_Brand%3DIsphere%20Cloud%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201d994ef657e22f95184a_Brand%3Dkonkconsulting-Logo%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
        'https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201d3e7d9209eca74c03ec_Brand%3DMagnisol-logo-color%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg',
    ];

    const logoCache = {};
    const loader = new THREE.TextureLoader();
    function preloadLogos() {

        const loadPromises = logos.map(logoFileName => {
            return new Promise((resolve, reject) => {
                loader.load(logoFileName, (loadedTexture) => {
                    logoCache[logoFileName] = loadedTexture;
                    resolve(loadedTexture);
                },
                    undefined, // onProgress callback not used here
                    (error) => {
                        console.error('Error loading the texture:', logoFileName, error);
                        reject(error);
                    });
            });
        });

        return Promise.all(loadPromises);
    }

    //Load elephantBusinessNodes
    gltfLoader.load(
        'https://d2t5w20sxjmcrj.cloudfront.net/assets/models/Elephant/glTF/elephantBusinessesNodesV2.gltf',
        (gltf) => {

            gltf.scene.traverse(child => {
                const randomElement = logos[Math.floor(Math.random() * logos.length)];

                if (child.isMesh) {
                    EBNMesh[child.name] = child
                    EBNMesh[child.name].logoFileName = randomElement
                }
            })

            EBNGroup.visible = false
            EBNGroup.add(gltf.scene)
            gltfModel.add(EBNGroup)
        }
    )

    //Load the elephant
    let model = null
    let particles = null
    let mixer = null
    var particleLogos = {}
    var particleBackgrounds = {}

    function adjustTime(t) {
        return t * t * (3 - 2 * t);
    }

    function updateParticles(t) {
        t = adjustTime(t);

        // Predefined vectors outside the loop to avoid repeated instantiation
        const decreaseY = new THREE.Vector3(0, -1.7, 0);
        const increaseX = new THREE.Vector3(3.5, 0, 0);
        const tempVector = new THREE.Vector3();
        const tempNewVector = new THREE.Vector3();

        var index = 0;
        for (var position of particles.positions) {
            var originalArray = particles.originalPositions[index].array;
            var randomArray = particles.randomPositions[index].array;

            for (let i = 0; i < particles.maxCount; i++) {
                const i3 = i * 3;

                tempVector.set(position.array[i3], position.array[i3 + 1], position.array[i3 + 2])
                    .add(decreaseY)
                    .add(increaseX);

                position.array[i3] = THREE.MathUtils.lerp(randomArray[i3], originalArray[i3], t);
                position.array[i3 + 1] = THREE.MathUtils.lerp(randomArray[i3 + 1], originalArray[i3 + 1], t);
                position.array[i3 + 2] = THREE.MathUtils.lerp(randomArray[i3 + 2], originalArray[i3 + 2], t);

                tempNewVector.set(position.array[i3], position.array[i3 + 1], position.array[i3 + 2])
                    .add(decreaseY)
                    .add(increaseX);

                var logo = particleLogos[tempVector.toArray()];
                var background = particleBackgrounds[tempVector.toArray()];

                if (logo && background) {
                    logo.position.copy(tempNewVector);
                    background.position.copy(tempNewVector);

                    logo.scale.multiplyScalar(0.985);
                    background.scale.multiplyScalar(0.985);

                    logo.material.depthTest = false;
                    background.material.depthTest = false;

                    updateLogoPosition({ point: tempNewVector }, logo);
                    updateBackgroundPosition({ point: tempNewVector }, background);

                    if (t > 0.99) {
                        logo.visible = false;
                        background.visible = false;
                    }

                    particleLogos[tempNewVector.toArray()] = logo;
                    particleBackgrounds[tempNewVector.toArray()] = background;
                }
            }
            position.needsUpdate = true;
            index++;
        }
    }

    const vertex = new THREE.Vector3();
    const boneMatrix = new THREE.Matrix4();
    const weightedVertex = new THREE.Vector3();
    const skinnedVertex = new THREE.Vector3();

    function updateElephantMovement() {
        if (!elephantLoaded || !mixer || !model || !particles) {
            return;
        }

        const deltaTime = clock2.getDelta();
        mixer.update(deltaTime); // Update animations

        let updated = false;
        const positionsArray = particles.geometry.attributes.position.array;
        let offset = 0; // Offset in the shared positionsArray

        let currentIndex = 0;

        model.traverse(object => {
            if (object.isSkinnedMesh && object.geometry && currentIndex < filteredIndices.length) {
                const { position: positionAttribute, skinIndex: skinIndices, skinWeight: skinWeights } = object.geometry.attributes;

                // Update the world matrix only if needed
                object.updateMatrixWorld(true);

                while (true) {

                    if (currentIndex > filteredIndices.length) {
                        break;
                    }

                    if (currentIndex > particles.offset && object.name == "Mesh017") {
                        break;
                    }

                    const index = filteredIndices[currentIndex++];

                    if (index == undefined) {
                        break;
                    }

                    if (index >= positionAttribute.count) {
                        break;
                    }

                    vertex.fromBufferAttribute(positionAttribute, index);
                   
                    applySkinningTransformations(vertex, object.skeleton, skinIndices, skinWeights, index);
                    
                    object.localToWorld(vertex); // Transform to world coordinates

                    if (offset < positionsArray.length) {
                        positionsArray[offset++] = vertex.x;
                        positionsArray[offset++] = vertex.y;
                        positionsArray[offset++] = vertex.z;
                        updated = true;
                    }
                }
            }
        });

        animateParticleFloating();

        if (updated) {
            particles.geometry.attributes.position.needsUpdate = true;
        }
    }


    var bonesToExclude = [
        "def-root",
        "def-back002",
        "def-back.002",
        "def-back001",
        "def-back.001",
        "def-shoulder002_L",
        "def-shoulder.002_L",
        "def-frontLeg_L",
        "def-frontShin_L",
        "def-frontFoot_L",
        "def-hip",
        "def-shoulder002_R",
        "def-shoulder.002_R",
        "def-frontLeg_R",
        "def-frontShin_R",
        "def-frontFoot_R",
        "def-hip_L",
        "def-backLeg_L",
        "def-backShin_L",
        "def-backFoot_L",
        "def-back003",
        "def-back.003",
        "def-hip_R",
        "def-backLeg_R",
        "def-backShin_R",
        "def-backFoot_R"
    ]

    function applySkinningTransformations(vertex, skeleton, skinIndices, skinWeights, i) {
        const skinIndex = new THREE.Vector4().fromBufferAttribute(skinIndices, i);
        const skinWeight = new THREE.Vector4().fromBufferAttribute(skinWeights, i);

        skinnedVertex.set(0, 0, 0);

        for (let i = 0; i < 4; i++) {
            const weight = skinWeight.getComponent(i);
            if (weight !== 0) {

                const boneIndex = Math.floor(skinIndex.getComponent(i));
                if (bonesToExclude.indexOf(skeleton.bones[boneIndex].name) > -1) {
                    return;
                }
                boneMatrix.multiplyMatrices(skeleton.bones[boneIndex].matrixWorld, skeleton.boneInverses[boneIndex]);
                weightedVertex.copy(vertex).applyMatrix4(boneMatrix).multiplyScalar(weight);
                skinnedVertex.add(weightedVertex);
            }
        }

        vertex.copy(skinnedVertex);
    }

    let duration = 7000; 

    function animateRandomizeParticles() {

        let now = Date.now();
        let elapsed = now - startTime;
        let t = elapsed / duration;

        updateParticles(t);

        if (elapsed < duration) {
            requestAnimationFrame(animateRandomizeParticles);

        } else {
            // Ensure the final position is nicely rounded off
            elephantLoaded = true;
        }
    }
    function animateParticleFloating() {
        const time = Date.now() * 0.005; // control the speed of movement
        const positionAttribute = particles.geometry.attributes.position;

        for (let i = 0, l = positionAttribute.count; i < l; i++) {
            // Adjust these values to control oscillation amplitude
            const amplitudeX = 0.003;
            const amplitudeY = 0.002;
            const amplitudeZ = 0.003;

            // Create an animation with different phases and scales for each axis
            // Harmonic motion parameters
            const offsetX = Math.sin(time + i * 0.1) * amplitudeX; // Lateral motion
            const offsetY = Math.cos(time + i * 0.2) * amplitudeY; // Vertical motion
            const offsetZ = Math.sin(time + i * 0.3) * amplitudeZ; // Forward-Backward motion

            // Update the particle's position using new offsets
            positionAttribute.setXYZ(
                i,
                positionAttribute.getX(i) + offsetX,
                positionAttribute.getY(i) + offsetY,
                positionAttribute.getZ(i) + offsetZ
            );
        }
    }

    var usedLogos = [];

    function getUnusedLogoIndex() {
        var logoIndex = Math.floor(Math.random() * logos.length);

        if (usedLogos.indexOf(logoIndex) == -1) {
            usedLogos.push(logoIndex);

            return logoIndex;
        } else {
            return getUnusedLogoIndex();
        }
    }

    function addLogos() {

        const frustum = new THREE.Frustum();
        const projScreenMatrix = new THREE.Matrix4();


        camera.updateMatrixWorld();  // Ensure the camera matrix is updated
        projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
        frustum.setFromProjectionMatrix(projScreenMatrix);

        var index = 0;
        particles.logos = [];
        for (var position of particles.positions) {

            var position = particles.positions[index]

            for (let i = 0; i < particles.maxCount; i++) {
                const i3 = i * 3

                var posVector = new Vector3(position.array[i3], position.array[i3 + 1], position.array[i3 + 2]);
                posVector.y -= 1.7;
                posVector.x += 3.5;

                var distance = posVector.distanceTo(camera.position);

                if (usedLogos.length > 10) {
                    continue;
                }

                if (!frustum.containsPoint(posVector) || distance > 6 || distance < 3) {
                    continue;
                }

                let posKey = posVector.toArray();  // Create a unique key

                var logoIndex = getUnusedLogoIndex();
                var fileName = logos[logoIndex];
                var texture = logoCache[fileName]

                // Create the logo mesh
                const widthScale = 0.18 / texture.image.width;
                const heightScale = 0.1 / texture.image.height;

                // Determine the minimum scale factor that maintains the aspect ratio
                const scale = Math.min(widthScale, heightScale);

                // Calculate the new width and height
                const newWidth = texture.image.width * scale;
                const newHeight = texture.image.height * scale;

                // Recreate the geometry with the new dimensions
                const logoGeometry = new THREE.PlaneGeometry(newWidth, newHeight);
                const logoMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true, side: THREE.DoubleSide });
                let logoMesh = new THREE.Mesh(logoGeometry, logoMaterial);
                scene.add(logoMesh); // Adding the logo mesh to the scene
                logoMesh.position.copy(posVector); // Setting the logo's position
                logoMesh.lookAt(camera.position);
                

                // Create the background mesh
                let backgroundMesh = createCircle(radius, segments);
                scene.add(backgroundMesh); // Adding the background mesh to the scene
                backgroundMesh.position.copy(logoMesh.position);
                backgroundMesh.lookAt(camera.position);
                backgroundMesh.position.z -= 0.05; // Slightly behind the logo

                updateLogoPosition({ point: posVector }, logoMesh);
                updateBackgroundPosition({ point: posVector }, backgroundMesh);
                particleLogos[posKey] = logoMesh;
                particleBackgrounds[posKey] = backgroundMesh;
            }

            index++;
        }
    };

    const skinningShader = {
        uniforms: {
            boneMatrices: { type: 'm4v', value: [] }, // an array of matrices
        },
        vertexShader: `
        attribute vec4 skinIndex;
        attribute vec4 skinWeight;
        uniform mat4 boneMatrices[4]; // Replace NUM_BONES with actual bone count

        void main() {
            mat4 boneMatrix = mat4(0.0);
            for (int i = 0; i < 4; i++) {
                boneMatrix += boneMatrices[int(skinIndex[i])] * skinWeight[i];
            }

            vec4 skinnedPosition = boneMatrix * vec4(position, 1.0);

            gl_Position = projectionMatrix * modelViewMatrix * skinnedPosition;
        }
    `,
        fragmentShader: `
        void main() {
            gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0); // Simple white, modify as needed.
        }
    `
    };

    const vertexShader = `attribute float size;
attribute vec3 color;
varying vec3 vColor;

void main() {
    vColor = color;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_PointSize = size * (300.0 / -mvPosition.z);
    gl_Position = projectionMatrix * mvPosition;
}
`;

    const fragmentShader = `
varying vec3 vColor;

void main() {
    vec2 coord = gl_PointCoord - vec2(0.5, 0.5);
    if (length(coord) > 0.5) {
        discard;
    }
    gl_FragColor = vec4(vColor, 1.0); // Use varying color passed from vertex shader
}
`;

    const shaderMaterial = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader, 
        //uniforms: {
        //    color: { value: new THREE.Color(0xafafaf) } // you can customize this
        //}
    });


    shaderMaterial.blending = THREE.AdditiveBlending; // Example blending mode, choose as needed
    var elephantLoaded = false;

    function mergePositions(child) {
        const attributes = child.children.flatMap(grandchild => {
            return grandchild.children.flatMap(grandchild2 => {
                // Log grandchild2 for debugging
                console.log(grandchild2);

                // Verify the grandchild2 object structure for geometry with position attribute
                if (grandchild2.geometry && grandchild2.geometry.attributes && grandchild2.geometry.attributes.position) {
                    // Return the position attribute BufferAttribute
                    return [grandchild2.geometry.attributes.position];
                } else {
                    return []; // No position data available, return empty array
                }
            });
        });

        // Merge the found attributes into a single BufferAttribute
        if (attributes.length > 0) {
            let totalLength = attributes.reduce((sum, attr) => sum + attr.array.length, 0);
            let mergedArray = new Float32Array(totalLength);
            let offset = 0;

            attributes.forEach(attr => {
                console.log(offset);
                particles.offset = offset;
                mergedArray.set(attr.array, offset);
                offset += attr.array.length;
            });

            return new THREE.BufferAttribute(mergedArray, attributes[0].itemSize);
        } else {
            return null; // Or return an empty BufferAttribute or similar based on your requirements
        }
    }

    particles = {}
    particles.hasFinalOffset = false;

    function filterVertices(positions, density) {
        density = Math.max(0, Math.min(density, 1));  // Clamp density to [0, 1]
        const filteredPositions = [];
        const filteredIndices = [];
        var offset = 0;

        for (const position of positions) {
            const originalArray = position.array;
            const filteredArray = [];
            const filterIndices = [];

            for (let i = 0; i < originalArray.length; i += 3) {
                if (Math.random() < density) {
                    filteredArray.push(originalArray[i + 0], originalArray[i + 1], originalArray[i + 2]);
                    filterIndices.push((i - offset) / 3);

                    if (i >= particles.offset && !particles.hasFinalOffset) {
                        console.log('set offet: ' + filterIndices.length)
                        offset = i;
                        particles.offset = filterIndices.length;
                        particles.hasFinalOffset = true;
                    }
                }
            }

            filteredPositions.push(new THREE.Float32BufferAttribute(new Float32Array(filteredArray), 3));
            filteredIndices.push(...filterIndices);
        }

        return { filteredPositions, filteredIndices };
    }

    var filteredIndices = []
  
    gltfLoader.load(
        'https://d2t5w20sxjmcrj.cloudfront.net/assets/models/Elephant/glTF/elephantModelAnimatedV2.gltf',
        (gltf) => {
            model = gltf.scene
            console.log(model)

            model.traverse((child) => {
                if (child.isMesh) {
                    child.castShadow = false;
                    child.receiveShadow = false;
                }
            });

            mixer = new THREE.AnimationMixer(gltf.scene)
            const action = mixer.clipAction(gltf.animations[0])
            action.play()

            /**
            * Particles
            */

            particles.index = 0

            // Positions
            var positions = model.children.flatMap(child => {
                return mergePositions(child);
            });

            console.log(positions)

            const result = filterVertices(positions, 0.5);

            positions = result.filteredPositions;
            filteredIndices = result.filteredIndices;

            console.log(positions)
            console.log(particles.offset)

            var maxX = -1000;
            var maxY = -1000;
            var maxZ = -1000;

            var minX = 1000;
            var minY = 1000;
            var minZ = 1000;

            particles.maxCount = 0

            

            for (const position of positions) {
                if (position.count > particles.maxCount)
                    particles.maxCount = position.count
            }

            particles.positions = []
            particles.originalPositions = [];
            particles.randomPositions = [];

            for (const position of positions) {
                const originalArray = position.array
                const newArray = new Float32Array(particles.maxCount * 3)

                for (let i = 0; i < particles.maxCount; i++) {
                    const i3 = i * 3

                    newArray[i3 + 0] = originalArray[i3 + 0] + (Math.random() * 0.005)
                    newArray[i3 + 1] = originalArray[i3 + 1] + (Math.random() * 0.005)
                    newArray[i3 + 2] = originalArray[i3 + 2] + (Math.random() * 0.005)

                    if (newArray[i3 + 0] > maxX) {
                        maxX = newArray[i3 + 0];
                    }

                    if (newArray[i3 + 1] > maxY) {
                        maxY = newArray[i3 + 1];
                    }

                    if (newArray[i3 + 2] > maxZ) {
                        maxZ = newArray[i3 + 2];
                    }

                    if (newArray[i3 + 0] < minX) {
                        minX = newArray[i3 + 0];
                    }

                    if (newArray[i3 + 1] < minY) {
                        minY = newArray[i3 + 1];
                    }

                    if (newArray[i3 + 2] < minZ) {
                        minZ = newArray[i3 + 2];
                    }
                }

                particles.originalPositions.push(new THREE.Float32BufferAttribute(newArray, 3))
            }

            for (const position of positions) {
                const originalArray = position.array
                const newArray = new Float32Array(particles.maxCount * 3)

                for (let i = 0; i < particles.maxCount; i++) {
                    const i3 = i * 3
                    if (i3 < originalArray.length) {
                        // can add random ness to the particles here "+ Math.random()"
                        const maxInitialDisplacement = 10;  // Increase or adjust scale to suit the scene
                        newArray[i3 + 0] = (Math.random() * (maxX - minX) + minX) * maxInitialDisplacement;
                        newArray[i3 + 1] = (Math.random() * (maxY - minY) + minY) * maxInitialDisplacement;
                        newArray[i3 + 2] = (Math.random() * (maxZ - minZ) + minZ) * maxInitialDisplacement;
                    }
                }
                let attribute = new THREE.Float32BufferAttribute(newArray, 3);
                attribute.needsUpdate = true;
                particles.positions.push(attribute);

                let attribute2 = new THREE.Float32BufferAttribute(newArray, 3);
                attribute2.needsUpdate = true;
                particles.randomPositions.push(attribute2);
            }

            // Geometry
            const sizesArray = new Float32Array(particles.maxCount)

            for (let i = 0; i < particles.maxCount; i++)
                sizesArray[i] = Math.random() * 0.15 + 0.008;

            particles.geometry = new THREE.BufferGeometry()
            particles.geometry.setAttribute('size', new THREE.BufferAttribute(sizesArray, 1));
            particles.geometry.setAttribute('position', particles.positions[particles.index])

            // Material
            const colors = new Float32Array(particles.maxCount * 3);
            var color = 0.8;
            for (let i = 0; i < particles.maxCount * 3; i += 3) {
                colors[i] = color;     // red
                colors[i + 1] = color;   // green
                colors[i + 2] = color;   // blue

                if (i > particles.offset * 3) {
                    color = 1;
                }
            }
            particles.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

            particles.material = shaderMaterial;
            particles.material.blending = THREE.NoBlending;

            // Points
            particles.points = new THREE.Points(particles.geometry, particles.material)
            particles.points.frustumCulled = false

            particleGroup.add(particles.points)
            particleGroup.translateY(-1.7)
            particleGroup.translateX(3.5)
            gltfModel.add(particleGroup)

            //model.material = skinningMaterial;
            gltfModel.add(model)

            model.visible = false;

            preloadLogos().then(() => {
                addLogos();

                setTimeout(() => {
                    
					startTime = Date.now();
                    animateRandomizeParticles();
                    lightsAnimationClip.play()
                }, 500);
            });
        })

/**
 *  Show a label over the elephant businsess node
 */

//Ray caster
const mouse = new THREE.Vector2()

    window.addEventListener('mousemove', (event)=>
    {
        mouse.x = event.clientX / sizes.width * 2 - 1
        mouse.y = -(event.clientY / sizes.height) * 2 + 1
    })


    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        alpha: true,
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(sizes.pixelRatio)

    function updateLogo(logoFileName) {
        if (logoCache[logoFileName]) {
            updateLogoMaterial(logoCache[logoFileName]);
        } else {
            loader.load(logoFileName, (loadedTexture) => {
                logoCache[logoFileName] = loadedTexture; // Cache loaded texture
                updateLogoMaterial(loadedTexture);
            });
        }
    }

    function updateLogoMaterial(texture) {
        texture.colorSpace = THREE.SRGBColorSpace;
        const widthScale = 0.18 / texture.image.width;
        const heightScale = 0.1 / texture.image.height;

        // Determine the minimum scale factor that maintains the aspect ratio
        const scale = Math.min(widthScale, heightScale);

        // Calculate the new width and height
        const newWidth = texture.image.width * scale;
        const newHeight = texture.image.height * scale;

        // Recreate the geometry with the new dimensions
        const newLogoGeometry = new THREE.PlaneGeometry(newWidth, newHeight);
       
        if (logoMesh) {
            scene.remove(logoMesh); // Remove the old mesh from the scene
        }

        // Create new material and mesh
        const logoMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true, side: THREE.DoubleSide });
        var oldPosition = logoMesh.position;
        logoMesh = new THREE.Mesh(newLogoGeometry, logoMaterial);
        logoMesh.position.copy(oldPosition);
        logoMesh.lookAt(camera.position);
        scene.add(logoMesh);
        logoMesh.visible = true; // Show the updated logo

        if (backgroundMesh) {
            scene.remove(backgroundMesh); // Remove the old mesh from the scene
        }

        // Create the geometry from the shape
        const circle = createCircle(radius, segments);
      
        // Create mesh
        var oldPosition2 = backgroundMesh.position;
        backgroundMesh = circle;
        backgroundMesh.castShadow = false;
        backgroundMesh.position.copy(oldPosition2);
        backgroundMesh.lookAt(camera.position);
        scene.add(backgroundMesh);
        backgroundMesh.visible = true; // Show the updated logo
    }

    const logoTexture = loader.load('https://assets-global.website-files.com/6617c828248b28fe621d4e60/66201e0d052ca2835cdd1463_Brand%3DHyperboliq%2C%20Color%3DFull%2C%20Size%3Dlg%2C%20Wordmark%3DFalse.svg');

    const logoGeometry = new THREE.PlaneGeometry(1 / 6, 0.5 / 6); // adjust size as needed
    const logoMaterial = new THREE.MeshBasicMaterial({ map: logoTexture, transparent: true, side: THREE.DoubleSide });
    let logoMesh = new THREE.Mesh(logoGeometry, logoMaterial);
    logoMesh.visible = false; // initially hide the logo
    scene.add(logoMesh);

    function createCircle(radius, segments) {
        const geometry = new THREE.CircleGeometry(radius, segments);
        const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const circle = new THREE.Mesh(geometry, material);

        return circle;
    }

    // Create the geometry from the shape
    const radius = 1 / 3.6 / 2; // Define the radius of the circle
    const segments = 32; // More segments means a smoother circle

    // Create mesh
    var backgroundMesh = createCircle(0.01, segments);
    backgroundMesh.position.z = -0.01; // Position slightly behind the logo
    backgroundMesh.castShadow = false;
    backgroundMesh.visible = false; // initially hide the logo
    scene.add(backgroundMesh);

    function updateLogoPosition(intersection, logoMesh) {
        const fixedDistance = 5; // Desired fixed distance from the camera

        // Direct vector calculation for position
        logoMesh.position.copy(camera.position)
            .add(
                intersection.point.clone().sub(camera.position).normalize().multiplyScalar(fixedDistance)
            );

        logoMesh.lookAt(camera.position);

        // Forward adjustment for logo to avoid z-fighting or minor visual adjustment
        const forwardOffset = intersection.point.clone().sub(camera.position).normalize().multiplyScalar(0.05);
        logoMesh.position.add(forwardOffset);
    }

    function updateBackgroundPosition(intersection, backgroundMesh) {
        const fixedDistance = 5.6; // Slightly further than the logo

        // Direct vector calculation for position
        backgroundMesh.position.copy(camera.position)
            .add(
                intersection.point.clone().sub(camera.position).normalize().multiplyScalar(fixedDistance)
            );

        backgroundMesh.lookAt(camera.position);

        // Upward adjustment for background to ensure it does not completely overlap the logo
        const upOffset = new THREE.Vector3().copy(camera.up).normalize().multiplyScalar(0.005);
        backgroundMesh.position.add(upOffset);
    }

    function updateBallPosition(intersection) {
        // Desired fixed distance from the camera to the logo
        const fixedDistance = 5; // Adjust this value as needed

        // Direction from camera to the intersection point
        const directionToIntersection = new THREE.Vector3().subVectors(intersection.point, camera.position).normalize();

        // Calculate the new position of the logo by moving along the direction to the intersection
        // from the camera position, but at a fixed distance
        const logoPosition = new THREE.Vector3().addVectors(camera.position, directionToIntersection.multiplyScalar(fixedDistance));

        // Set the logo's position
        backgroundMesh.position.copy(logoPosition);

        // Ensure the logo always faces the camera
        backgroundMesh.lookAt(camera.position);
    }

    let animationState = 'inactive'; // 'inactive', 'growing', 'showLogo'

    // Track last mouse position
    let lastMouseX, lastMouseY;

    document.addEventListener('mousemove', (event) => {
        // Normalize current mouse coordinates to range [-1, 1]
        const x = (event.clientX / window.innerWidth) * 2 - 1;
        const y = -(event.clientY / window.innerHeight) * 2 + 1;

        if (lastMouseX !== undefined && lastMouseY !== undefined) {
            // Calculate change in mouse position
            const deltaX = x - lastMouseX;
            const deltaY = y - lastMouseY;

            // Convert delta into a change in angle
            const sensitivity = 0.01; // Control sensitivity
            const deltaPhi = sensitivity * deltaY * Math.PI;
            const deltaTheta = sensitivity * deltaX * Math.PI;

            // Existing radius computed based on current camera position
            const currentRadius = Math.sqrt(camera.position.x ** 2 + camera.position.y ** 2 + camera.position.z ** 2);

            // Calculate new angles based on current camera angles and increments
            let phi = Math.atan2(Math.sqrt(camera.position.x ** 2 + camera.position.z ** 2), camera.position.y) - deltaPhi;
            let theta = Math.atan2(camera.position.z, camera.position.x) - deltaTheta;

            // Update the camera position according to new angles
            camera.position.x = currentRadius * Math.sin(phi) * Math.cos(theta);
            camera.position.y = currentRadius * Math.cos(phi);
            camera.position.z = currentRadius * Math.sin(phi) * Math.sin(theta);
        }

        // Update the last processed mouse positions
        lastMouseX = x;
        lastMouseY = y;

        camera.lookAt(new Vector3(0, 0, 0));
    });

    const interval = 1000; // X milliseconds, as required
    let prevTime = Date.now();
    var currentLogoPosition;
    const clock2 = new THREE.Clock()

    const animate = () => {
        requestAnimationFrame(animate);

        if (elephantLoaded) {
            const currentTime = Date.now();

            if (currentTime - prevTime > interval) {
                prevTime = currentTime;

                if (animationState === 'showLogo') {
                    logoMesh.visible = false;
                    backgroundMesh.visible = false;
                    animationState = 'inactive';
                }

                if (animationState === 'inactive' && particles != null && particles.positions.length > 0) {
                    const randomIndex = Math.floor(Math.random() * particles.maxCount);
                    const i3 = randomIndex * 3;

                    // Get a random position from particles array
                    const positionBuffer = particles.positions[0].array; // Assuming it's stored in the 1st entry of an array.
                    var x = positionBuffer[i3];
                    var y = positionBuffer[i3 + 1];
                    const z = positionBuffer[i3 + 2];

                    y = y - 1.7;
                    x = x + 3.5;

                    backgroundMesh.position.set(x, y, z); // Set to random particle position
                    backgroundMesh.visible = true;
                    backgroundMesh.scale.set(0.01, 0.01, 0.01); // Start small

                    updateBallPosition({ point: backgroundMesh.position });
                    currentLogoPosition = backgroundMesh.position;
                    animationState = 'growing';
                }
            }

            if (animationState === 'growing') {
                backgroundMesh.scale.multiplyScalar(1.038);

                if (backgroundMesh.scale.x > 0.47) {

                    updateBallPosition({ point: currentLogoPosition });
                    backgroundMesh.visible = true;

                    animationState = 'showLogo';
                    prevTime = Date.now();
                }
            }

            if (animationState === 'showLogo') {

                if (!logoMesh.visible) {
                    var logoIndex = Math.floor(Math.random() * logos.length);
                    updateLogo(logos[logoIndex], logoMesh, backgroundMesh);
                }

                backgroundMesh.visible = true;
                logoMesh.visible = true;

                updateLogoPosition({ point: currentLogoPosition }, logoMesh);
                updateBackgroundPosition({ point: currentLogoPosition }, backgroundMesh);
            }
        }

        if (elephantShouldAnimate)
            updateElephantMovement();

        renderer.render(scene, camera);
    };

    animate();
    camera.lookAt(new Vector3(0, 0, 0));
}

export function animateElephant(shouldAnimate) {
    elephantShouldAnimate = shouldAnimate
}