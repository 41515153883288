import ThreeGlobe from "three-globe";
import { WebGLRenderer, Scene } from "three";
import {
    PerspectiveCamera,
    AmbientLight,
    DirectionalLight,
    Color,
    Fog,
    PointLight,
    Vector3,
    Quaternion
    
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import countries from "../assets/globe-data-min.json";
import travelHistory from "../assets/my-flights.json";
import offices from "../assets/my-offices.json";

export function buildGlobe() {


    var renderer, camera, scene, controls;
    var Globe;

    var oldPosition = 0;
    function handleScroll(event) {
        var currentPosition = document.documentElement.scrollTop;

        var newPosition = currentPosition - oldPosition;

        oldPosition = currentPosition;
   
        if (newPosition > 0) {
            rotateCamera(Math.PI / 4, new Vector3(0, 0.02, 0));
        } else {
            rotateCamera(Math.PI / 4, new Vector3(0, -0.02, 0));
        }
    }

    function rotateCamera(angle, axis) {
        // Store the distance from the camera to the controls' target
        const distance = controls.target.distanceTo(camera.position);

        // Apply your desired rotation to the camera
        const quaternion = new Quaternion();
        quaternion.setFromAxisAngle(axis, angle);
        camera.position.sub(controls.target); // Translate to pivot (target)
        camera.position.applyQuaternion(quaternion); // Rotate around pivot
        camera.position.add(controls.target); // Translate back

        // Look at the controls target without updating the OrbitControls
        camera.lookAt(controls.target);
        camera.updateMatrixWorld();

        // Restore the initial camera distance (zoom level)
        const direction = camera.position.clone().sub(controls.target).normalize();
        camera.position.copy(controls.target).add(direction.multiplyScalar(distance));

        // If you want to update OrbitControls' internal state to match the new camera position
        // temporarily enable it, run update, then disable it again
        controls.update();
    }


    init();
    initGlobe();
    onWindowResize();
    animate();


    // SECTION Initializing core ThreeJS elements
    function init() {

        window.addEventListener('scroll', handleScroll);

        // Initialize renderer
        renderer = new WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.useLegacyLights = true;
        const container2 = document.getElementById('animation-container');
        container2.appendChild(renderer.domElement);

        // Initialize scene, light
        scene = new Scene();

        //The Lights color
        scene.add(new AmbientLight(0xffffff, 0.4));

        //Background color of the whole globe section.
        scene.background = new Color(0x111b42);

        // Initialize camera, light
        camera = new PerspectiveCamera();
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        // Hid this light for now.
        var dLight = new DirectionalLight(0xffffff, 0.2);
        dLight.position.set(-800, 2000, 400);
        //camera.add(dLight);

        // Light at the top
        var dLight1 = new DirectionalLight(0xffffff, 1);
        dLight1.position.set(-200, 500, 200);
        camera.add(dLight1);

        //Light at the bottom
        var dLight2 = new PointLight(0xffffff, 0.9);
        dLight2.position.set(-200, -500, -200);
        camera.add(dLight2);

        camera.position.z = 180;
        camera.position.x = 0;
        camera.position.y = 200;

        scene.add(camera);

        // Additional effects
        // Took this out to make it more crisp.
        // scene.fog = new Fog(0x535ef3, 400, 2000);

        // Helpers
        // const axesHelper = new AxesHelper(800);
        // scene.add(axesHelper);
        // var helper = new DirectionalLightHelper(dLight);
        // scene.add(helper);
        // var helperCamera = new CameraHelper(dLight.shadow.camera);
        // scene.add(helperCamera);

        //// Initialize controls
        controls = new OrbitControls(camera, renderer.domElement);
        //controls.enableDamping = true;
        //controls.dynamicDampingFactor = 0.01;
        controls.enablePan = false;
        controls.minDistance = 200;
        controls.maxDistance = 500;
        controls.rotateSpeed = 0.8;
        controls.enableZoom = false;
        controls.zoomSpeed = 1;
        controls.autoRotate = false;
        controls.enabled = true;

        
        controls.addEventListener('start', function () {
            renderer.domElement.style.cursor = 'grabbing';
        });

        controls.addEventListener('end', function () {
            renderer.domElement.style.cursor = 'default';
        });

        window.addEventListener("resize", onWindowResize, false);
    }

    // SECTION Globe
    function initGlobe() {
        // Initialize the Globe
        Globe = new ThreeGlobe({
            waitForGlobeReady: true,
            animateIn: true,
            position: "(1000,1000,1000)"
        })
            .hexPolygonsData(countries.features)
            .hexPolygonResolution(3)
            .hexPolygonMargin(0.7)
            .showAtmosphere(true)
            // Atmosphere color
            .atmosphereColor("#002E94")
            .atmosphereAltitude(0.15) //was 0.25
            .hexPolygonColor((e) => {
                if (
                    ["KGZ", "KOR", "THA", "RUS", "UZB", "IDN", "KAZ", "MYS"].includes(
                        e.properties.ISO_A3
                    )
                ) {
                    return "rgba(255,255,255, 1)";
                } else return "rgba(255,255,255, 1)";
            });

        // NOTE Arc animations are followed after the globe enters the scene
        setTimeout(() => {
            Globe.arcsData(travelHistory.flights)
                .arcColor((e) => {
                    // Arcs for travel's colors, might be slightly off brand here.
                    return e.status ? "#FDB515" : "#FDB515";
                })
                .arcAltitude((e) => {
                    return e.arcAlt;
                })
                .arcStroke((e) => {
                    return e.status ? 0.8 : 0.6; // was 0.5 and 0.3
                })
                .arcDashLength(0.9)
                .arcDashGap(4)
                .arcDashAnimateTime(5000)
                .arcsTransitionDuration(5000)
                .arcDashInitialGap((e) => e.order * 1)
                .labelsData(offices.offices)
                // City Label colors
                .labelColor(() => "#ffffff")
                .labelDotOrientation((e) => {
                    return e.text === "ALA" ? "top" : "right";
                })
                .labelDotRadius(0.3)
                .labelSize((e) => e.size)
                .labelText("city")
                .labelResolution(16)
                .labelAltitude(0.01)
                .pointsData(offices.offices)
                // City colors, off brand as well.
                .pointColor(() => "#FDB515")
                .pointsMerge(true)
                .pointAltitude(0.07)
                .pointRadius(0.15); //was 0.05
        }, 200);

        //Globe.rotateY(-Math.PI * (5 / 9));
        //Globe.rotateZ(-Math.PI / 6);
        const globeMaterial = Globe.globeMaterial();
        
       globeMaterial.color = new Color(0x003FFF);
        //globeMaterial.color = new Color(0x003399);
        globeMaterial.emissive = new Color(0xC0D0D9);
        globeMaterial.emissiveIntensity = 0.0;
        globeMaterial.shininess = 50;

        // NOTE Cool stuff
        // globeMaterial.wireframe = true;

        scene.add(Globe);
    }

    function onMouseMove(event) {
        //mouseX = event.clientX - windowHalfX;
        //mouseY = event.clientY - windowHalfY;
        // console.log("x: " + mouseX + " y: " + mouseY);
    }

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    }
    function animate() {
        camera.lookAt(scene.position);
        controls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
    }

}