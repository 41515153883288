import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import CustomEase from "gsap/CustomEase";
import { Observer } from "gsap/all";

const scrollHandlers = [];
export function buildScroll() {

    document.addEventListener("DOMContentLoaded", function () {
        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase);

        let pages = 9;
        let offsets = [];
        let isCanvasInteracting = false;

        let height = window.innerHeight == 1 ? window.screen.height : window.innerHeight

        for (let i = 0; i < pages; i++) {
            offsets.push(i * height);
        }

        let currentSectionIndex = 0;

        if (window.scrollY > 0) {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 100);
        }

        function goToSection(index) {
            if (index < 0 || index >= offsets.length || index === currentSectionIndex) return;

            const y = offsets[index];

            console.log(y);

            gsap.to(window, {
                scrollTo: { y: y, autoKill: true },
                duration: 1,
                ease: CustomEase.create("custom", ".645,.045,.355,1")
            });

            currentSectionIndex = index;
            notifyHandlers(index);
        }

        function notifyHandlers(index) {
            scrollHandlers.forEach(handler => handler(index));
        }

        const observer = Observer.create({
            type: "wheel,touch,pointer",
            wheelSpeed: -0.05,
            onDown: () => {
                if (gsap.isTweening(window)) return;
                goToSection(currentSectionIndex - 1);
                console.log("on up");
            },
            onUp: () => {
                if (gsap.isTweening(window)) return;
                goToSection(currentSectionIndex + 1);
                console.log("on down");
            },
            tolerance: 10,
            preventDefault: true,
            //onDrag: function (self) {
            //    // Prevent dragging default behavior
            //    self.event.preventDefault();
            //}
        });

        // Add touchstart and touchend event listeners for better mobile support
        //window.addEventListener('touchstart', function (event) {
        //    // Store the initial touch position
        //    this.touchStartY = event.touches[0].clientY;
        //});

        //window.addEventListener('touchend', function (event) {
        //    // Determine the touch direction
        //    let touchEndY = event.changedTouches[0].clientY;
        //    let touchDeltaY = touchEndY - this.touchStartY;

        //    if (Math.abs(touchDeltaY) > 50) { // Adjust swipe threshold according to your needs
        //        if (touchDeltaY < 0) {
        //            // Swiped up
        //            goToSection(currentSectionIndex + 1);
        //        } else {
        //            // Swiped down
        //            goToSection(currentSectionIndex - 1);
        //        }
        //    }
        //});

        // Dynamically update offsets on resize to handle viewport height changes on mobile
        window.addEventListener("resize", function () {
            offsets = [];
            let height = window.innerHeight == 1 ? window.screen.height : window.innerHeight

            for (let i = 0; i < pages; i++) {
                offsets.push(i * height);
            }
        });

        const canvas = document.getElementById('animation-container');

        // Add event listeners to capture interaction on canvas
        canvas.addEventListener('pointerdown', () => {
            isCanvasInteracting = true;
            observer.disable(); // Disable the observer when interacting with canvas
            console.log('disable')
        });

        canvas.addEventListener('pointerup', () => {
            isCanvasInteracting = false;
            observer.enable(); // Re-enable the observer after interacting with canvas
            console.log('enable')
        });

        canvas.addEventListener('pointermove', (e) => {
            if (isCanvasInteracting) {
                // Handle the Three.js globe movement here
            }
        });
    });
}

export function registerScrollHandler(handler) {
    if (typeof handler === 'function') {
        scrollHandlers.push(handler);
    }
}